import { handleLogin, isLoggedIn } from '../../../utils/auth'

import Form from '..'
import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { navigate } from 'gatsby'

class Login extends React.Component<RouteComponentProps> {
  state = {
    username: ``,
    password: ``,
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleUpdate(event: any): void {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit(event: React.FormEvent<Element>): void {
    event.preventDefault()
    handleLogin(this.state)
  }

  render(): React.ReactElement {
    if (isLoggedIn()) {
      navigate(`/app/profile`)
    }

    return <Form handleUpdate={this.handleUpdate} handleSubmit={this.handleSubmit} />
  }
}

export default Login
