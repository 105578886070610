import Layout from 'layouts'
import Login from 'components/form/login'
import React from 'react'
import { Router } from '@reach/router'
import SEO from 'components/head'
import Status from 'components/auth/status'
import { metaTagData } from 'config'

const App = (): React.ReactElement => (
  <>
    <SEO metaTagData={metaTagData} />
    <Layout>
      <Status />
      <Router>
        <Login path="/app/login" />
      </Router>
    </Layout>
  </>
)
export default App

/*

import Profile from '../components/profile';
<Profile path="/app/profile" />
      <Login path="/app/login" />
*/
