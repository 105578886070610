import { isWebBrowser } from 'utils'

const authKey = 'gatsbyUser'

interface IUserObject {
  email: string
  name: string
  legalName: string
}
const getUser = (): IUserObject | undefined => {
  if (isWebBrowser()) {
    return localStorage.gatsbyUser ? JSON.parse(localStorage.gatsbyUser) : undefined
  }
  return undefined
}

const setUser = (user: IUserObject): IUserObject | undefined => {
  if (!isWebBrowser) {
    return
  }
  localStorage.setItem(authKey, JSON.stringify(user))
  return user
}
const clearUser = (): void => {
  if (!isWebBrowser) {
    return
  }
  localStorage.removeItem(authKey)
}

export const handleLogin = ({ username, password }: { username: string; password: string }): undefined | IUserObject => {
  if (!isWebBrowser) return undefined

  if (username === `gatsby` && password === `demo`) {
    console.log(`Credentials match! Setting the active user.`)
    return setUser({
      name: `Jim`,
      legalName: `James K. User`,
      email: `jim@example.org`,
    })
  }

  return undefined
}

export const isLoggedIn = (): IUserObject | undefined => {
  const user = getUser()
  if (user) {
    return user
  }
  return undefined
  // return !!user.email
}

export const getCurrentUser = (): IUserObject | undefined => getUser()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const logout = (callback: any): void => {
  if (!isWebBrowser) return

  console.log(`Ensuring the \`gatsbyUser\` property exists.`)
  clearUser()
  callback()
}
